import { sendEvent } from "src/analytics/sendEvent";
/**
 * @deprecated If using the Tags API, use a lifecycle onMount instead.
 */
export interface Input {
  category: string;
  action: string;
  label?: string;
  value?: number;
}

<lifecycle
  onMount() {
    sendEvent({
      category: input.category,
      action: input.action,
      label: input.label,
      value: input.value,
      isNonInteraction: true,
    });
  }
/>
